import React from 'react'
import { programsData } from '../../data/programsData';
import RightArrow from '../../assets/rightArrow.png';

import './Programs.css';

export const Programs = () => {
  return (
    <div className="programs" id="programs">
      <div className="programs-header">
        <span className="stroke-text">Explore</span>
        <span>PROGRAMS</span>
        <span className="stroke-text">TO SHAPE YOU</span>
      </div>
      <div className="program-categories">
        {
          programsData.map((program) => {
            return (
              <div key={program.heading} className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="join-now">
                  <span>Join now</span>
                  <img src={RightArrow} alt="" />
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};
