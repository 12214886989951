import React from "react";
import { plansData } from '../../data/plansData';
import whiteTick from '../../assets/whiteTick.png'

import "./Plans.css";

export const Plans = () => {
  return (
    <div className="plans-container" id="plans">
      <div className="blur plans-blur1"></div>
      <div className="blur plans-blur2"></div>
      <div className="programs-header">
        <span className="stroke-text">READY TO START</span>
        <span>YOUR JOURNEY</span>
        <span className="stroke-text">NOW WITHUS</span>
      </div>
      <div className="plans">{plansData.map((plan, i) => (
        <div key={plan.name} className="plan">
          {plan.icon}
          <span>{plan.name}</span>
          <span>$ {plan.price}</span>
          <div className="features">
            {plan.features.map((feature) => (
              <div key={feature} className="feature">
                <img src={whiteTick} alt="" />
                <span>{feature}</span>
              </div>
            ))}
          </div>
          <div><span>See more benefits -&gt;</span></div>
              <button className="btn">Join now</button>
        </div>
      ))}</div>
    </div>
  );
};
