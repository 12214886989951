import React, { useState } from "react";

import "./Header.css";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";

export function Header() {
  const isMobile = window.innerWidth < 768;
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const onMenuOpen = () => {
    setIsMenuOpened(true);
  };

  const onItemClick = () => {
    setIsMenuOpened(false);
  };

  return (
    <div className="header">
      <img src={Logo} alt="" className="logo" />
      {!isMenuOpened && isMobile ? (
        <div onClick={onMenuOpen}>
          <img src={Bars} alt="" className="burger" />
        </div>
      ) : (
        <ul className="header-menu">
          <li>
            <Link to="home" span smooth onClick={onItemClick}>Home</Link>
          </li>
          <li>
            <Link to="programs" span smooth onClick={onItemClick}>Programs</Link>
          </li>
          <li>
            <Link to="reasons" span smooth onClick={onItemClick}>Why us</Link>
          </li>
          <li>
            <Link to="plans" span smooth onClick={onItemClick}>Plans</Link>
          </li>
          <li>
            <Link to="testimonials" span smooth onClick={onItemClick}>
              Testimonials
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
}
