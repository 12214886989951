import React, { useState } from "react";
import { motion } from 'framer-motion';
import { testimonialsData } from '../../data/testimonialsData';
import leftArrow from '../../assets/leftArrow.png';
import rightArrow from '../../assets/rightArrow.png';


import "./Testimonials.css";

export const Testimonials = () => {
  const [selectedIdx, setSelectedIdx] = useState(0);
  const tLength = testimonialsData.length;
  const testimonial = testimonialsData[selectedIdx];
  const transition = { type: 'spring', duration: 3 };

  return (
    <div className="testimonials">
      <div className="left-t">
        <span>TESTIMONIALS</span>
        <span className="stroke-text">WHAT THEY</span>
        <span>SAY ABOUT US</span>
        <motion.span
          key={selectedIdx}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={transition}
        >
          {testimonial.review}
        </motion.span>
        <span>
          <span className="reviewer-name">{testimonial.name}</span>
          {' '}
          - {testimonial.status}
        </span>
      </div>
      <div className="right-t">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ...transition, duration: 2 }}
        ></motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ...transition, duration: 2 }}
        ></motion.div>
        <motion.img
          src={testimonial.image}
          alt=""
          key={selectedIdx}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={transition}
        />
        <div className="arrows">
          <img
            src={leftArrow}
            alt=""
            onClick={() => {
              setSelectedIdx(
                (prevValue) => (prevValue === 0 ? tLength - 1 : prevValue - 1)
              );
            }}
          />
          <img
            src={rightArrow}
            alt=""
            onClick={() => {
              setSelectedIdx(
                (prevValue) => (prevValue === tLength - 1 ? 0 : prevValue + 1)
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};
